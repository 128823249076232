import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import UserInfo from "../views/User-info.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/user-info",
    name: "UserInfo",
    component: UserInfo,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
