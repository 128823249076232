<template>
  <div class="user-info--background_img_style">
    <div class="modal-container" @click="closeModal()">
      <div class="modal-image">
        <img src="../assets/hoodie.png" style="width: 100%" />
      </div>
    </div>

    <div style="width: 100%; height: 100%; overflow-y: auto">
      <div>
        <a href="/">
          <img src="../assets/logo.svg" class="logo_img_style1" alt="logo" />
        </a>
      </div>

      <div v-if="user_info" class="user-info--content_style">
        <div class="user-info--txt_content">
          <h1 class="user-info--header_style">
            Order your Sitecore<br />
            new-hire gift
          </h1>
          <p class="user-info--sub_content_style">
            Please complete the form to<br />
            receive your new-hire gift.
          </p>
          <a
            class="user-info--link_txt_style"
            style="color: white"
            @click="showModal()"
          >
            Click here to view the<br />
            hoodie conversion chart
          </a>
        </div>

        <div class="user-info--user-info-field">
          <div class="txt-double-contaner">
            <input
              type="text"
              class="txt_field_style"
              placeholder="Preferred name*"
              v-model="preferred_name"
            />
            <input
              type="text"
              class="txt_field_style"
              placeholder="Surname*"
              v-model="surname"
            />
          </div>
          <div class="txt-double-contaner">
            <!-- <VueDatePicker
              v-model="start_date"
              type="date"
              format="DD-MM-YYYY"
              placeholder="Start date at Sitecore*"
              class="txt_field_style"
              :locale="{lang: 'en'}"
            /> -->
            <input
              type="text"
              name="start_date"
              placeholder="Start Date*"
              class="txt_field_style"
            />

            <input
              type="text"
              class="txt_field_style"
              placeholder="Email address*"
              v-model="email"
            />
          </div>
          <p style="font-size: 0.8em; margin-bottom: 5px">
            Shipments cannot be made unless contact number is complete
          </p>
          <div class="txt-double-contaner">
            <!-- <input
              type="text"
              class="txt_field_style"
              placeholder="Mobile phone*"
              v-model="phone"
            /> -->
            <vue-tel-input
              class="txt_field_style"
              style="padding: 0; background-color: white"
              v-model="phone"
              v-bind="props"
            />
            <v-select
              v-model="hoodie_size"
              style="bacground-color: white"
              :options="options"
              placeholder="Please select your size*"
            />
          </div>
          <!-- <div class="txt-single-contaner">
            <input
              type="text"
              class="txt_field_style"
              placeholder="Delivery address*"
              v-model="address"
            />
          </div> -->
          <div class="txt-single-contaner">
            <input
              type="text"
              class="txt_field_style"
              placeholder="First line of address*"
              v-model="firstLineOfAddress"
            />
          </div>
          <div class="txt-single-contaner">
            <input
              type="text"
              class="txt_field_style"
              placeholder="Second Line of Address (optional)"
              v-model="secondLineOfAddress"
            />
          </div>
          <div class="txt-single-contaner">
            <input
              type="text"
              class="txt_field_style"
              placeholder="Town/City*"
              v-model="town"
            />
          </div>
          <div class="txt-single-contaner">
            <input
              type="text"
              class="txt_field_style"
              placeholder="Postal/Zip code*"
              v-model="zip_code"
            />
          </div>
          <div class="txt-single-contaner">
            <country-select
              v-model="country"
              :country="country"
              placeholder="Select Country*"
              topCountry="GB"
              :style="{ color: !country ? 'darkgray' : 'black' }"
              class="selct--style"
            />
          </div>
          <div class="txt-single-contaner" v-if="country === 'US'">
            <region-select
              v-model="state"
              :region="state"
              placeholder="Select State*"
              :style="{ color: !state ? 'darkgray' : 'black' }"
              class="selct--style"
            />
          </div>
          <div class="txt-single-contaner">
            <p class="txt_description_style">
              This information will be processed in accordance with our
              <a
                href="https://www.sitecore.com/trust/privacy-policy"
                target="_blank"
                style="color: #221f20; text-decoration: underline"
                >privacy policy</a
              ><br />
              and used only for the purpose of sending you the Sitecore new-hire
              gift.
            </p>
          </div>
          <div
            class="txt-single-contaner"
            style="justify-content: start !important; width: 55%"
          >
            <input
              type="button"
              class="btn_style1"
              value="REDEEM NOW"
              style="font-size: 2em; letter-spacing: 0.05em"
              @click="showEditDialog()"
            />
          </div>
        </div>
      </div>
      <template v-else>
        <div class="welcome-msg">
          <div class="welcome_content">
            <h1 class="welcome_header">
              Thank you<br />
              for these details.
            </h1>
            <p class="welcome_sub_content">
              We will now start putting together<br />
              your new-hire gift.
            </p>
            <p class="welcome_sub_content">
              And once again,<br />
              welcome to the Sitecore team!
            </p>
            <p class="welcome_last_content">
              Please allow up to four weeks for your shipment to be delivered.
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import Router from "@/router/index.js";
// import DatePicker from "vue2-datepicker";

// import { VueDatePicker } from "@mathieustan/vue-datepicker";
// import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

// import { Datepicker } from 'vanillajs-datepicker';
import flatpickr from "flatpickr";
import moment from "moment";
import { VueTelInput } from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";
// import "vue-tel-input/dist/css/sprite.css";
// import "vue-tel-input/dist/css/component.css";

import Vue from "vue";
import vueCountryRegionSelect from "vue-country-region-select";
Vue.use(vueCountryRegionSelect);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

const renderDatePicker = () => {
  const elem = document.querySelector('input[name="start_date"]');
  flatpickr(elem, {
    dateFormat: "d-m-Y",
  });
};

export default {
  name: "UserInfo",

  components: {
    // VueDatePicker,
    VueTelInput,
  },
  data() {
    return {
      user_info: true,
      preferred_name: "",
      surname: "",
      start_date: "",
      email: "",
      phone: "+44 123 456 7890",
      hoodie_size: "",
      address: "",
      zip_code: "",
      country: "",
      state: "",
      notes: "",
      options: ["S", "M", "L", "XL", "XXL"],

      firstLineOfAddress: "",
      secondLineOfAddress: "",
      town: "",

      props: {
        mode: "international",
        preferredCountries: ["GB", "US"],
        defaultCountry: "GB",
        enabledFlags: true,
        placeholder: "Mobile Phone*",
        enabledCountryCode: true,
        inputOptions: {
          showDialCode: true,
          tabindex: 0,
        },
        validCharactersOnly: false,
      },

      headers: [
        { text: "Id", value: "id" },
        { text: "User_Code", value: "User_Code" },
        { text: "Name", value: "Name" },
        { text: "Surname", value: "Surname" },
        { text: "Start Date", value: "date" },
        { text: "Email", value: "email" },
        { text: "Mobile Phone", value: "mobile phone" },
        { text: "Hoodie Size", value: "hoodie size" },
        { text: "Delivery Address", value: "delivery address" },
        { text: "Postal Code", value: "postal code" },
        { text: "Country", value: "country" },
        { text: "State", value: "state" },
        { text: "Notes", value: "notes" },
      ],
      items: [],
      dialog: false,
      editedItem: {},
    };
  },

  created() {
    this.preferred_name = this.$store.state.g_UserData.Name;
    this.surname = this.$store.state.g_UserData.Surname;
    // if (this.$store.state.g_UserData.Start_Date.includes("-")) {
    //   let time = this.$store.state.g_UserData.Start_Date.split("-");
    //   this.$store.state.g_UserData.Start_Date =
    //     time[2] + "/" + time[1] + "/" + time[0];
    // }
    this.start_date = this.$store.state.g_UserData.Start_Date;
    this.email = this.$store.state.g_UserData.Email;
    this.phone = this.$store.state.g_UserData.Mobile_Phone;
    this.hoodie_size = this.$store.state.g_UserData.Hoodie_Size;
    this.address = this.$store.state.g_UserData.Delivery_Address;
    this.zip_code = this.$store.state.g_UserData.Postal_Code;
    this.country = this.$store.state.g_UserData.Country;
    this.state = this.$store.state.g_UserData.State;
    this.notes = this.$store.state.g_UserData.Notes;
    console.log("created");
    setTimeout(() => {
      renderDatePicker();
    }, 1000);
  },

  methods: {
    showEditDialog(item) {
      this.editedItem = item || {};
      this.dialog = !this.dialog;
      this.saveItem();
    },
    async saveItem() {
      // console.log(this.$store.state.g_User_Code);
      // console.log(this.$store.state.g_Id);

      const dateElm = document.querySelector('input[name="start_date"]');
      if (!dateElm) {
        return alert("Please reload the page and try again");
      }
      let start_date = dateElm.value;
      if (!start_date) return alert("Please fill in all the fields");
      start_date = moment(start_date, "DD-MM-YYYY").format("MM-DD-YYYY");
      console.log("start_date", start_date);

      if (
        !this.preferred_name ||
        !this.surname ||
        !this.email ||
        !this.phone ||
        !this.hoodie_size ||
        !this.firstLineOfAddress ||
        !this.zip_code ||
        !this.country ||
        !this.town ||
        (this.country === "US" && !this.state)
      ) {
        // console.log('this.preferred_name', this.preferred_name);
        // console.log('this.surname', this.surname);
        // console.log('this.start_date', this.start_date);
        // console.log('this.email', this.email);
        // console.log('this.phone', this.phone);
        // console.log('this.hoodie_size', this.hoodie_size);
        // console.log('this.firstLineOfAddress', this.firstLineOfAddress);
        // console.log('this.zip_code', this.zip_code);
        // console.log('this.country', this.country);
        // this.user_info = true;
        // Router.push({ path: "/user-info" });
        alert("Please fill in all the fields");
      } else {
        // if (this.start_date.includes("/")) {
        //   let time = this.start_date.split("/");
        //   this.start_date = time[2] + "-" + time[1] + "-" + time[0];
        //   console.log(this.start_date);
        // } else if (this.start_date.includes(" ")) {
        //   let time = this.start_date.split("/");
        //   this.start_date = time[2] + "-" + time[1] + "-" + time[0];
        //   console.log(this.start_date);
        // }

        let item = {
          Name: this.preferred_name,
          Surname: this.surname,
          Start_Date: start_date,
          Email: this.email,
          Mobile_Phone: this.phone,
          Hoodie_Size: this.hoodie_size,
          Delivery_Address:
            this.firstLineOfAddress +
            " - " +
            this.secondLineOfAddress +
            " - " +
            this.town,
          Postal_Code: this.zip_code,
          Country: this.country,
          State: this.state,
          Notes: this.notes,
          Submitted_At: new Date().toISOString(),
        };

        //let method = "patch";
        let id = this.$store.state.g_Id;
        //let url = `https://api.airtable.com/v0/${airTableApp}/${airTableName}/${id}`;

        if (!id) {
          alert(
            "You need to go back to home page and enter the PIN again, click OK to redirect to home page"
          );
          window.location = "/";
          return;
        }
        try {
          const res = await this.axios.post("/api/submit", {
            id,
            fields: item,
          });
          if (res.data.message !== "OK") {
            return alert(res.data.message);
          }
          this.user_info = false;
          this.dialog = !this.dialog;
        } catch (error) {
          console.log(error);
        }
      }
    },
    showModal() {
      document.querySelector(".modal-container").style.display = "flex";
    },
    closeModal() {
      document.querySelector(".modal-container").style.display = "none";
    },
    getHoodSize(e) {
      //console.log(e.target.value);
      this.hoodie_size = e.target.value;
    },
  },
};
</script>

<style>
.vs__selected {
  color: inherit;
}
option {
  color: black;
}
.user-info--background_img_style {
  background-image: url("../assets/sub_background.png");
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center;
}
.logo_img_style1 {
  margin-top: 50px;
  margin-left: 60px;
}
.user-info--txt_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
}
.user-info--content_style {
  max-width: 1300px;
  margin: auto;
  display: flex;
  justify-content: space-around;
}
.user-info--header_style {
  color: white;
  font-family: "AvenirNextLTPro-Bold";
  font-size: 45px;
  font-weight: 700;
  z-index: 2;
}
.user-info--sub_content_style {
  color: white;
  font-family: "AvenirNextLTPro-Regular";
  font-size: 23px;
  padding: 20px 0;
  z-index: 2;
}
.user-info--link_txt_style {
  color: white;
  font-family: "AvenirNextLTPro-Regular";
  font-size: 23px;
  text-decoration: underline;
  z-index: 2;
}
.user-info--user-info-field {
  padding: 20px 30px;
  background-color: rgb(246, 246, 246);
  z-index: 2;
  font-size: 1em;
}
.txt-double-contaner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 10px 0;
}
.txt-double-contaner .txt_field_style {
  border: none;
  font-family: "MyriadPro-Regular";
  font-size: 1em;
  color: #221f20 !important;
  padding: 10px;
  height: 50px;
  text-align: left;
}
.txt-double-contaner .txt_field_style:focus-visible {
  outline: none;
}
.txt-single-contaner {
  display: grid;
  grid-template-columns: 1fr;
  font-size: 1em;
  margin: 10px 0;
}
.txt-single-contaner .txt_field_style {
  border: none;
  font-family: "MyriadPro-Regular";
  font-size: 1em;
  color: #221f20;
  padding: 10px;
  height: 50px;
  text-align: left;
}
.txt-single-contaner .txt_field_style:focus-visible {
  outline: none;
}
.txt-single-contaner .notes_field_style {
  border: none;
  font-size: 1em;
  color: #221f20;
  padding: 10px;
  height: 50px;
  text-align: left;
  height: 100px;
}
.txt-single-contaner .notes_field_style:focus-visible {
  outline: none;
}
.btn_style1 {
  font-family: "AvenirNextLTPro-Bold";
  padding: 10px 15px 4px 15px;
  background-color: rgb(28, 166, 163);
  color: white;
  font-size: 25px;
  font-weight: 700;
  border: none;
}
.welcome-msg {
  width: 100%;
  height: 80%;
  display: flex;
}
.welcome_content {
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  z-index: 1;
  padding: 1em 5em;
  font-size: 1vw;
}
.welcome_header {
  color: rgb(28, 166, 163);
  font-family: "AvenirNextLTPro-Bold";
  /* font-size: 2em; */
  font-size: 40px;
  padding: 10px 0;
}
.welcome_sub_content {
  font-family: "AvenirNextLTPro-Regular";
  /* font-size: 1.5em; */
  font-size: 32px;
}
.welcome_last_content {
  font-family: "AvenirNextLTPro-Regular";
  /* font-size: 1em; */
  font-size: 20px;
}
.user-info--txt_content a:hover {
  cursor: pointer;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: none;
  align-items: center;
  justify-content: center;
}

.modal-image {
  width: 80%;
  max-width: 700px;
  background-color: white;
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: translate(-50% -50%) scale(0);
  }
  to {
    -webkit-transform: translate(-50% -50%) scale(1);
  }
}

@keyframes zoom {
  from {
    -webkit-transform: translate(-50% -50%) scale(0);
  }
  to {
    -webkit-transform: translate(-50% -50%) scale(1);
  }
}
.vd-wrapper .vd-picker__input .vd-picker__input-icon {
  display: none !important;
}
.vd-picker__input input {
  font-size: 1em !important;
  color: #221f20 !important;
}
.vd-wrapper {
  background-color: white !important;
  padding: 3.5px 10px !important;
  color: darkgrey !important;
}
.vd-picker__input input::placeholder {
  color: darkgrey !important;
  opacity: 1; /* Firefox */
}
::placeholder {
  color: darkgrey !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: darkgrey !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: darkgrey !important;
}
.txt_description_style {
  font-family: "MyriadPro-Regular";
  font-size: 18px;
  color: #221f20;
}
#vs1__combobox {
  border: none;
  background-color: white;
  border-radius: 0;
  height: 35px;
}
.selct--style {
  height: 35px;
  border: none;
  width: 100% !important;
}
.vs__clear {
  display: none !important;
}
.vti__country-code {
  display: none !important;
}
@media only screen and (max-width: 1440px) {
  .user-info--content_style {
    max-width: 1000px;
  }
  .txt-double-contaner .txt_field_style {
    height: 35px;
  }
  .user-info--header_style {
    font-size: 35px;
  }
  .user-info--sub_content_style {
    font-size: 19px;
  }
  .user-info--link_txt_style {
    font-size: 19px;
  }
  .txt-single-contaner .txt_field_style {
    height: 35px;
  }
  .txt-double-contaner {
    margin: 17px 0;
  }
  .txt-single-contaner {
    margin: 17px 0;
  }
  .datetime-picker input {
    width: 150px;
    height: 38px;
  }
  .txt_description_style {
    font-size: 17px;
  }
  .welcome_header {
    font-size: 30px;
    padding: 20px 0;
  }
  .welcome_sub_content {
    font-size: 22px;
  }
  .welcome_last_content {
    font-size: 15px;
    margin: 0;
  }
}
@media only screen and (max-width: 1024px), (max-height: 649px) {
  .user-info--content_style {
    max-width: 640px;
    margin: 60px auto;
    flex-direction: column;
  }
  .user-info--txt_content {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .user-info--content_style {
    max-width: 500px;
  }
}
@media only screen and (max-width: 576px) {
  .logo_img_style1 {
    margin-top: 25px;
    margin-left: 30px;
  }
  .txt-double-contaner {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .user-info--content_style {
    max-width: 350px;
  }
  .txt-double-contaner {
    margin: 0;
  }
  .user-info--header_style {
    font-size: 35px;
  }
  .txt-double-contaner {
    flex-direction: column;
  }
  .txt-double-contaner .txt_field_style {
    margin: 10px 0px;
  }
  .txt_description_style {
    font-size: 16px;
  }
  .welcome_content {
    margin: auto 20px;
  }
  .txt-single-contaner {
    margin-bottom: 17px;
  }
}
/* @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .user-info--txt_content {
    padding-top: 130px !important;
  }
  .user-info--header_style {
    font-size: 25px;
  }
  .user-info--sub_content_style {
    font-size: 15px;
    padding: 0 !important;
  }
  .user-info--link_txt_style {
    font-size: 15px;
  }
  .txt-double-contaner .txt_field_style {
    font-size: 10px;
    width: 30vw;
  }
  .txt-single-contaner .txt_field_style {
    font-size: 10px;
    width: calc(60vw + 20px);
  }
  .txt-single-contaner .notes_field_style {
    font-size: 10px;
    width: calc(60vw + 20px);
  }
  .txt_description_style {
    font-size: 2.4em;
  }
  .btn_style1 {
    font-size: 3em !important;
  }
  .vd-menu__content {
    top: 200px !important;
    left: 30px !important;
  }
  .user-info--content_style {
    height: auto !important;
  }
  .user-info--background_img_style {
    height: auto !important;
  }
} */

@media only screen and (max-height: 750px) and (min-width: 1025px) {
  .user-info--content_style {
    position: relative;
    top: -35px;
    height: 100vh;
  }
}

@media only screen and (max-height: 700px) and (min-width: 1025px) {
  .user-info--content_style {
    position: relative;
    top: -100px;
    height: 100vh;
  }
}

@media only screen and (max-height: 650px) and (min-width: 1025px) {
  .user-info--content_style {
    position: static;
    top: unset;
    height: unset;
  }
}
</style>
