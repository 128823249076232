<template>
  <div class="background_img_style" style="overflow: hidden">
    <div
      style="
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        overflow-y: auto;
      "
    >
      <div>
        <a href="/">
          <img src="../assets/logo.svg" class="logo_img_style" alt="logo" />
        </a>
      </div>

      <div class="content_style">
        <h2 class="header_style">Welcome to the Sitecore Team</h2>
        <p class="subtext_style">
          We’re thrilled to have you on board. Together as one team,<br />
          we are poised for greatness, through accelerated growth<br />
          and boundless opportunities.<br /><br />
          We are excited for our journey together and for your career<br />
          here at Sitecore!<br /><br />
          We would like to celebrate by sending you a welcome gift,<br />
          to make your experience extra special. Please provide us<br />
          with your details and we will take care of the rest.<br /><br />
          Warmly,<br />
          Your Sitecore Team
        </p>
        <div class="unique_code_style">
          <p class="label_style">
            ENTER YOUR UNIQUE CODE<br />
            TO RECEIVE YOUR SITECORE<br />
            NEW-HIRE GIFT<br />
          </p>

          <div class="text_btn_style">
            <input
              type="text"
              class="txt_style"
              maxlength="5"
              v-model="User_code"
            />
            <div class="btn_style" @click="loadItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="white"
                class="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Router from "@/router/index.js";

export default {
  name: "Home",

  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "User_Code", value: "User_Code" },
        { text: "Name", value: "Name" },
        { text: "Sur Name", value: "sur Name" },
        { text: "Start Date", value: "date" },
        { text: "Email", value: "email" },
        { text: "Mobil Phone", value: "mobile phone" },
        { text: "Hoodie Size", value: "hoodie size" },
        { text: "Delivery Address", value: "delivery address" },
        { text: "Postal code", value: "postal code" },
        { text: "Country", value: "country" },
        { text: "Notes", value: "notes" },
      ],
      items: [],
    };
  },

  methods: {
    async loadItems() {
      if (!this.User_code) {
        this.boxTwo = "";
        this.$bvModal
          .msgBoxOk(
            "The code you entered isn’t recognised. Please enter a valid code to continue",
            {
              title: "",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            }
          )
          .then((value) => {
            this.boxTwo = value;
          });

        this.User_code = "";
        Router.push({ path: "/" });
      } else {
        this.items = [];

        try {
          const result = await this.axios.get(`/api/check/${this.User_code}`);
          console.log(result);
          this.$store.commit("setUserData", result.data.records);
          console.log(this.$store.state.g_UserData);
          this.$store.commit("setCode", result.data.records.User_Code);
          this.$store.commit("setId", result.data.Id);
          Router.push({ path: "/user-info" });
        } catch (error) {
          console.log("error", error);

          this.boxTwo = "";
          this.$bvModal
            .msgBoxOk(
              "The code you entered isn’t recognised. Please enter a valid code to continue",
              {
                title: "",
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true,
              }
            )
            .then((value) => {
              this.boxTwo = value;
            });
        }
      }
    },
  },
};
</script>

<style>
.background_img_style {
  background-image: url("../assets/main_background.png");
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center;
}
.logo_img_style {
  margin-top: 50px;
  margin-left: 60px;
}
.header_style {
  color: white;
  font-family: "AvenirNextLTPro-Bold";
  font-size: 86px;
  max-width: 100%;
  line-height: 100%;
  z-index: 2;
}
.content_style {
  max-width: 700px;
  font-size: 1em;
  margin: 100px auto;
}

.subtext_style {
  color: white;
  font-family: "AvenirNextLTPro-Regular";
  font-size: 22px;
  line-height: normal;
  max-width: 100%;
}
.label_style {
  color: white;
  font-family: "AvenirNextLTPro-Bold";
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
}
.unique_code_style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text_btn_style {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  width: 50%;
  height: 95%;
  max-width: 300px;
}
.btn_style {
  background-color: rgb(28, 166, 163);
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}
.txt_style {
  height: 90%;
  text-align: center;
  font-family: "AvenirNextLTPro-Regular";
  font-size: 40px;
  line-height: 40px;
  max-width: 70%;
  border: none;
  padding-top: 3px;
}
.txt_style:focus-visible {
  outline: none;
}
.modal-backdrop {
  opacity: 0.6 !important;
}
.btn-success {
  background-color: rgb(28, 166, 163) !important;
  border-color: rgb(28, 166, 163) !important;
}
@media only screen and (max-width: 1440px) {
  .content_style {
    max-width: 600px;
    margin: 45px auto;
  }
  .header_style {
    font-size: 4.2em;
  }
  .subtext_style {
    font-size: 1.1em;
  }
  .label_style {
    font-size: 1.1em;
  }
  .text_btn_style {
    width: 70%;
  }
}
@media only screen and (max-width: 1024px) {
  .content_style {
    max-width: 550px;
  }
  .unique_code_style {
    flex-direction: column;
  }
  .header_style {
    font-size: 3.5em;
  }
  .subtext_style {
    font-size: 0.9em;
  }
  .label_style {
    font-size: 0.9em;
  }
  .text_btn_style {
    width: 70%;
  }
}
@media only screen and (max-width: 768px) {
  .content_style {
    max-width: 450px;
  }
  .text_btn_style {
    width: 80%;
  }
}
@media only screen and (max-width: 576px) {
  .logo_img_style {
    margin-top: 25px;
    margin-left: 30px;
  }
  .content_style {
    max-width: 310px;
  }
  .header_style {
    font-size: 2.5em;
  }
  .subtext_style {
    font-size: 0.7em;
  }
  .label_style {
    font-size: 0.7em;
  }
  .text_btn_style {
    width: 65%;
  }
}
</style>
